import React from 'react';

import PostFeed from './PostFeed';
import Footer from '../Footer';
import BuildRelationshipsSection from '../sections/BuildRelationshipsSection';
import Header from '../Header';
import Seo from '../Seo';

const CategoryTemplateDetails = ({ data, pageContext }) => {
  const { allWpPost } = data;
  const { category } = pageContext;

  console.log(pageContext);

  const posts = allWpPost.edges.map((edge) => edge.node);

  return (
    <>
      <Seo title={`${category} Articles`} />

      <Header graphic={undefined}>{`${category} Articles`}</Header>

      <div className="mx-auto w-full px-4 pt-12 sm:px-6 sm:pt-16 lg:flex lg:justify-between lg:px-8 lg:pt-20 mb-16">
        <main className="prose mx-auto lg:prose-xl hover:prose-a:text-sp-green">
          <div className="max-w-screen-xl mx-auto ">
            <PostFeed posts={posts} />
          </div>
        </main>
      </div>

      <BuildRelationshipsSection />

      <Footer />
    </>
  );
};

export default CategoryTemplateDetails;
