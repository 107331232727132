import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import CategoryDetails from '../components/blog/CategoryDetails';

const CategoryTemplate = (props) => {
  const { data, pageContext } = props;
  const { title } = data.wp.generalSettings;
  const { category } = pageContext;

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{`${category} - ${title}`}</title>
          <meta name="description" content={`${title} - ${category}`} />
        </Helmet>
        <CategoryDetails {...props} />
      </div>
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query ($category: String!) {
    site {
      siteMetadata {
        menu {
          title
          uri
          external
        }
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
    allWpPost(filter: { categories: { nodes: { elemMatch: { name: { eq: $category } } } } }) {
      edges {
        node {
          title
          date
          excerpt
          slug
          author {
            node {
              id
              name
            }
          }
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              sourceUrl
              title
            }
          }
        }
      }
    }
  }
`;
